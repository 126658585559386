export const imageOptimization = (url?: string) => {
  if (url) {
    const parsedUrl = new URL(url)
    parsedUrl.searchParams.delete('branch')
    parsedUrl.searchParams.set('auto', 'webp')
    return parsedUrl.toString()
  }
  return url
}

export const customImageLoaderForDXL = ({
  src,
  width,
  quality = 90,
}: {
  src: string
  width?: number | string
  quality?: number | string
}) => {
  const url = new URL(src)

  const searchParams = url.searchParams

  searchParams.set('fmt', searchParams.get('fmt') || 'pjpeg')
  searchParams.set('pscan', searchParams.get('pscan') || 'auto')
  searchParams.set('op_sharpen', searchParams.get('op_sharpen') || '0')
  searchParams.set('resMode', searchParams.get('resMode') || 'sharp2')
  searchParams.set('op_usm', searchParams.get('op_usm') || '0.5,1,5,0')
  searchParams.set('iccEmbed', searchParams.get('iccEmbed') || '0')
  searchParams.set('printRes', searchParams.get('printRes') || '72')
  searchParams.set('layer', searchParams.get('layer') || '0')

  searchParams.set('format', searchParams.get('format') || 'pjpg')
  searchParams.set('auto', searchParams.get('auto') || 'avif')

  searchParams.set('qlt', searchParams.get('qlt') || `${quality}`)

  if (width) {
    searchParams.set('wid', `${width}`)
  }

  return url.toString()
}

export const customImageLoaderForContentstack = ({
  src,
  width,
  quality = 90,
}: {
  src: string
  width?: number | string
  quality?: number | string
}) => {
  const url = new URL(src)

  const searchParams = url.searchParams

  searchParams.set('format', searchParams.get('format') || 'pjpg')
  searchParams.set('auto', searchParams.get('auto') || 'avif')
  searchParams.set('quality', searchParams.get('quality') || `${quality}`)

  if (width) {
    searchParams.set('width', `${width}`)
  }

  return url.toString()
}
